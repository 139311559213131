import React, {memo} from 'react';
// import History from '../../components/history';
import Layout from '../../components/layout';
import ProfileCard from '../../components/profile-card';
import History from '../../components/history';

const Account = () => {
  return (
    <Layout title="My Account" showLogo>
      <div className="px-3">
        <ProfileCard />
        <History />
      </div>
    </Layout>
  );
};

export default memo(Account);
