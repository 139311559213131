import React, {memo, useCallback, useEffect, useRef, useState} from 'react';
import {getRequestsHistory} from '../../requests';
import HistoryTable from '../history-table';
import loop from '../../img/icons/search-icon.svg';
import './styles.scss';

const History = () => {
  const [result, setResult] = useState([]);
  const [total, setTotal] = useState(0);
  const [loading, setLoading] = useState(false);

  const ref = useRef(null);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(25);
  const [source, setSource] = useState('');
  const [sort, setSort] = useState({sortBy: 'scanned_at', type: 'desc'});
  const [startLoader, setStartLoader] = useState(true);
  const [change, setChange] = useState(false);
  const getList = useCallback(
    async (data: any) => {
      setLoading(true);
      const resp = await getRequestsHistory(data);
      const finList =
        resp?.result &&
        Array.isArray(resp.result) &&
        resp.result.map((item: any) => {
          const parsedData = item.result && JSON.parse(item.result);
          const tool = parsedData?.toolName;
        //   const tab = tabs.find((tab: any) => tab.value === tool);
        //   const toolName = tab && tab.label;
        //   const toolIcon = tab && tab.icon;
          const keyword = parsedData?.keyword;
          return {
            ...item,
            keyword: keyword,
            csv: parsedData?.csvURL,
            json: parsedData?.jsonURL,
          };
        });
      setResult(finList || []);
      setTotal(resp?.total || 0);
      setLoading(false);
      setStartLoader(false);
    },
    [getRequestsHistory],
  );

  useEffect(() => {
    const sortBy = Object.values(sort);
    getList({
      page,
      limit,
      source,
      order: {
        [sortBy[0]]: sortBy[1],
      },
    });
  }, [getList, page, limit, source, sort]);

  const handleClick = useCallback(() => {
    setSource(ref && ref.current ? ref.current.value : '');
    setPage(1);
    setChange(true);
  }, [ref]);

  const handleCaptureOnEnter = useCallback(
    (e: any) => {
      if (e.key === 'Enter') {
        setSource(ref && ref.current ? ref.current.value : '');
        setPage(1);
        setChange(true);
      }
    },
    [ref],
  );
  return (
    <div className={startLoader ? 'w-100 text-center' : 'w-100'}>
      {startLoader ? (
        <div className="spinner-border text-secondary mx-auto" role="status">
          <span className="sr-only"></span>
        </div>
      ) : (result && result.length > 0) || change ? (
        <div className="card-container">
          <div className="card">
            <div className="table-search-container">
              <input
                className={`input is-rounded inputBar`}
                type="text"
                placeholder={'Search...'}
                onKeyPress={handleCaptureOnEnter}
                maxLength={320}
                ref={ref}
              />
              <img src={loop} onClick={handleClick} className="loop-icon" />
            </div>
            {loading ? (
              <div className="spinner-border mx-auto my-4 text-secondary" role="status">
                <span className="sr-only"></span>
              </div>
            ) : result.length < 1 ? (
              <div className="primary-text w-100 text-center">Nothing to show</div>
            ) : (
              <HistoryTable
                list={result}
                total={total}
                page={page}
                setPage={setPage}
                limit={limit}
                setLimit={setLimit}
                sort={sort}
                setSort={setSort}
              />
            )}
          </div>
        </div>
      ) : (
        <div className="primary-text w-100 text-center">Nothing to show</div>
      )}
    </div>
  );
};

export default memo(History);
