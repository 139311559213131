import React, { FC } from 'react';
import './styles.scss';

const CustomProgress:FC<any> = ({percent, text, noMessage}) => {
  return (
    <div className="progress-section">
      <div className={`final__animation-bar-2 ${noMessage ? '' : 'show-tooltip'}`}>
        <span data-label={text || percent + '%'} style={{width: percent + '%'}} className="tooltip-bg"></span>
      </div>
    </div>
  );
};

export default CustomProgress;
