import React, {FC, memo, useState} from 'react';
import {navigate} from 'gatsby';
import Pagination from '../../components/pagination';
import {getDateAndTime, handleDownload} from '../../helpers';
import {downloadIcon} from '../../img/svg-icons';
import './styles.scss';

const options = [5, 15, 25, 30, 50];

const HistoryTable: FC<any> = ({list, total, sort, setSort, page, setPage, limit, setLimit}) => {
  const [showMenu, setShowMenu] = useState(false);

  const handleRowOptionClick = (val: any) => {
    setLimit(val);
    setPage(1);
    setShowMenu(false);
  };

  const handleChangeSortByDate = () => {
    setSort({sortBy: 'scanned_at', type: sort.type === 'desc' && sort.sortBy === 'scanned_at' ? 'asc' : 'desc'});
  };

  const handleChangeSortBySource = () => {
    setSort({sortBy: 'source', type: sort.type === 'desc' && sort.sortBy === 'source' ? 'asc' : 'desc'});
  };

  const handleChangePage = (val: any) => {
    setPage(val);
  };

  const handleNavigate = (src: string) => {
    src && localStorage.setItem('text-reader-result', src);
    src && navigate(`/${new Date().getTime()}`);
  };

  return (
    <>
      <div className=" w-100 history_table mx-auto">
        <div className="row w-100 table-header">
          <div className={`primary-text col-md-5 text-left`} onClick={handleChangeSortBySource}>
            Text
            <span className={`sorting-arrow ${sort.sortBy === 'source' ? 'active' : ''}`}>
              {sort.type === 'desc' ? '↓' : '↑'}
            </span>
          </div>
          <div className={`primary-text col-md-3 text-left`} onClick={handleChangeSortByDate}>
            Scan date
            <span className={`sorting-arrow ${sort.sortBy === 'scanned_at' ? 'active' : ''}`}>
              {sort.type === 'desc' ? '↓' : '↑'}
            </span>
          </div>
          <div className={`primary-text col-md-2 text-left`}></div>
          <div className="primary-text col-md-3"></div>
        </div>
        {list.map((item: any) => (
          <div className="cursor-pointer row w-100 table-body mb-2" key={item.id}>
            <div className={`secondary-text text-truncate col-sm-12 col-xl-5 text-left my-auto`}>{JSON.parse(item.result)?.source}</div>
            <div className={`secondary-text col-sm-4 col-xl-3 text-sm-xl-right text-left my-auto`}>
              {getDateAndTime(item.scanned_at)}
            </div>
            <div className={`secondary-text col-sm-4 col-xl-2 text-sm-lg-right text-left my-auto`}>
              <span
                className={`status-badge ${
                  item.status === 'success' ? 'success' : item.status === 'prending' ? 'primary' : 'fail'
                }`}
              >
                {item.status}
              </span>
            </div>

            <div className="col-sm-4 col-xl-2 text-right d-flex my-auto">
              <div onClick={() => handleDownload(JSON.parse(item.result)?.mp3URL)} className="mr-2 my-auto">
                {downloadIcon}
              </div>
              <div
                className="clickable-text-bold my-auto"
                style={{whiteSpace: 'nowrap'}}
                onClick={() => handleNavigate(JSON.parse(item.result)?.mp3URL)}
              >
                See result →
              </div>
            </div>
          </div>
        ))}
      </div>
      <div className="table-footer">
        <div className="table-footer-left d-flex">
          <span className="row-num-lable">Rows per page</span>
          <div className="dropdown">
            <button className="btn btn-secondary dropdown-toggle" onClick={() => setShowMenu(!showMenu)}>
              {limit}
            </button>
            {showMenu && (
              <div className="dropdown-menu">
                {options.map(option => (
                  <div key={option} className="dropdown-item" onClick={() => handleRowOptionClick(option)}>
                    {option}
                  </div>
                ))}
              </div>
            )}
          </div>
        </div>
        {Math.ceil(total / limit) > 1 && (
          <div className="table-footer-right">
            <Pagination
              activePage={page}
              onChange={handleChangePage}
              totalCount={Math.ceil(total / limit)}
              itemsPerPage={1}
            />
          </div>
        )}
      </div>
    </>
  );
};

export default memo(HistoryTable);
